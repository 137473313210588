<template>
  <div>
    <a-button
      v-if="verifyAccess(['all', 'parent_details'])"
      class="m-2"
      @click="visibleDetails = true"
      >{{ $t("chat.parentDetails") }}</a-button
    >

    <a-dropdown v-if="verifyListEleveAccess()">
      <a-menu slot="overlay" @click="handleChildClick">
        <a-menu-item v-for="student in parentData.students" :key="student._id">
          <a-icon type="user" />{{ student.firstName }}
          {{ student.lastName }}</a-menu-item
        >
      </a-menu>
      <a-button style="margin-left: 8px">
        {{ $t("menu.eleves") }} <a-icon type="down" />
      </a-button>
    </a-dropdown>

    <a-modal
      :dialog-style="{
        /*top: '10px'*/
      }"
      :title="$t('chat.parentDetails')"
      :visible="visibleDetails"
      width="40%"
      @cancel="() => (visibleDetails = false)"
      @ok="() => (visibleDetails = false)"
    >
      <a-divider>{{ $t("sms.mere") }}</a-divider>
      <p>
        <b>{{ $t("liste.nomMere") }} : </b>{{ parentData.mother.firstName }}
      </p>
      <p>
        <b>{{ $t("liste.prenomMere") }} : </b>{{ parentData.mother.lastName }}
      </p>
      <p>
        <b>{{ $t("liste.telMere") }} : </b
        ><a-tag color="blue">{{ parentData.mother.phone }}</a-tag>
      </p>
      <a-divider>{{ $t("sms.pere") }}</a-divider>
      <p>
        <b>{{ $t("liste.nomPere") }} : </b>{{ parentData.father.firstName }}
      </p>
      <p>
        <b>{{ $t("liste.prenomPere") }} : </b>{{ parentData.father.lastName }}
      </p>
      <p>
        <b>{{ $t("liste.telPere") }} : </b
        ><a-tag color="blue"> {{ parentData.father.phone }}</a-tag>
      </p>
    </a-modal>
  </div>
</template>

<script>
/* eslint-disable */

import { mapState } from "vuex";
import apiClient from "@/services/axios";

export default {
  props: ["currentParent"],
  data: function () {
    return {
      parentData: { mother: {}, father: {} },
      visibleDetails: false,
    };
  },
  created() {
    this.updateParentData();
  },
  computed: {
    ...mapState(["user", "settings"]),
  },
  methods: {
    updateParentData() {
      apiClient
        .post("/parents/filter", {
          query: { status: "active", _id: this.currentParent },
          aggregation: [
            {
              $lookup: {
                from: "students",
                localField: "students",
                foreignField: "_id",
                as: "students",
              },
            },
            // { $unwind: "$students" },
            {
              $project: {
                father: 1,
                mother: 1,
                students: 1,
              },
            },
          ],
        })
        .then(({ data }) => {
          this.parentData = data[0];
        });
    },
    handleChildClick(val) {
      const routeData = this.$router.resolve({
        path: "/eleve/" + val.key,
      });
      window.open(routeData.href, "_blank");
    },
    verifyAccess(accessRoles) {
      const existing = this.user.access;
      if (existing.all) return true;
      if (
        existing.chat &&
        existing.chat.length > 0 &&
        existing.chat.find((e) => accessRoles.includes(e))
      )
        return true;
      else return false;
    },
    verifyListEleveAccess() {
      const existing = this.user.access;
      if (existing.all) return true;
      if (
        existing.liste_eleves &&
        existing.liste_eleves.length > 0 &&
        existing.liste_eleves.find((e) => ["all", "liste_eleves"].includes(e))
      )
        return true;
      else return false;
    },
  },
  watch: {
    currentParent: function () {
      this.updateParentData();
    },
  },
};
</script>
