<template>
  <div style="width: 97%">
    <a-modal
      :dialog-style="{
        top: '25%',
      }"
      :title="$t('chat.startNewConversationWithParent')"
      :visible="visibleAddParnet"
      width="40%"
      @cancel="handleCancel"
    >
      <div class="d-flex">
        <a-icon type="user" class="mt-2 mr-3" />
        <a-select
          show-search
          style="width: 100%"
          option-filter-prop="children"
          :placeholder="$t('recette.selectionnerParent')"
          :filter-option="filterOption"
          :allow-clear="false"
          v-model="selectedParent"
        >
          <a-select-option
            :value="item._id"
            v-for="item in parentList"
            :key="item._id"
            :disabled="checkIfParentAlreadyAdded(item._id)"
          >
            {{ item.father.firstName + " " + item.father.lastName }}
            /
            {{ item.mother.firstName + " " + item.mother.lastName }}
          </a-select-option>
        </a-select>
      </div>
      <template slot="footer">
        <a-button key="back" @click="cancelAddParent">
          {{ $t("action.annuler") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          :disabled="!selectedParent"
          @click="handleAddParent"
        >
          {{ $t("chat.addParent") }}
        </a-button>
      </template>
    </a-modal>
    <vue-advanced-chat
      height="calc(100vh - 120px)"
      class="mr-10"
      :current-user-id="currentUserId"
      :rooms="JSON.stringify(rooms)"
      :text-messages="JSON.stringify(textMessages)"
      show-reaction-emojis="false"
      show-audio="false"
      :show-add-room="roomsLoaded ? 'true' : 'false'"
      :messages="JSON.stringify(messages)"
      :message-actions="JSON.stringify(messageActions)"
      :messages-loaded="messagesLoaded"
      :rooms-loaded="roomsLoaded"
      @send-message="sendMessage($event.detail[0])"
      @fetch-messages="fetchMessages($event.detail[0])"
      @delete-message="deleteMessageHandler($event.detail[0])"
      @open-file="openFile($event.detail[0])"
      @add-room="addNewParent($event.detail[0])"
      load-first-room="true"
      show-new-messages-divider="false"
    >
      <div slot="room-options" v-if="selectedRoom">
        <div style="width: 450px; display: flex; justify-content: right">
          <PaymentStatus
            :currentParent="selectedRoom.roomId"
            v-if="verifyAccess(['all', 'payment_status'])"
          />
          <ParentDetails :currentParent="selectedRoom.roomId" />
        </div>
      </div>
    </vue-advanced-chat>
  </div>
</template>

<script>
/* eslint-disable */

import { register } from "vue-advanced-chat";
import apiClient from "@/services/axios";
import { mapState } from "vuex";
import PaymentStatus from "@/components/status/paymentStatus.vue";
import ParentDetails from "./components/parentDetails.vue";
import moment from "moment";

register();
export default {
  computed: {
    ...mapState(["settings", "user"]),
  },
  components: {
    PaymentStatus,
    ParentDetails,
  },
  data() {
    return {
      selectedParent: undefined,
      visibleAddParnet: false,
      parentList: [],
      roomsLoaded: false,
      currentUserId: "0",
      rooms: [],
      messages: [],
      messagesLoaded: false,
      selectedRoom: null,
      unseenMessages: [],
      messageActions: [
        {
          name: "deleteMessage",
          title: "Delete Message",
          onlyMe: true,
        },
      ],
      textMessages: {
        ROOMS_EMPTY: this.$t("chat.ROOMS_EMPTY"),
        ROOM_EMPTY: this.$t("chat.ROOM_EMPTY"),
        NEW_MESSAGES: this.$t("chat.NEW_MESSAGES"),
        MESSAGE_DELETED: this.$t("chat.MESSAGE_DELETED"),
        MESSAGES_EMPTY: this.$t("chat.MESSAGES_EMPTY"),
        CONVERSATION_STARTED: this.$t("chat.CONVERSATION_STARTED"),
        TYPE_MESSAGE: this.$t("chat.TYPE_MESSAGE"),
        SEARCH: this.$t("chat.SEARCH"),
        IS_ONLINE: this.$t("chat.IS_ONLINE"),
        LAST_SEEN: this.$t("chat.LAST_SEEN"),
      },
    };
  },

  beforeDestroy() {
    this.$socket.client.off("parentSeen");
    this.$socket.client.off("chat-status");
    this.$socket.client.off("adminMessage");
  },

  async created() {
    await this.fetchAndUpdateRooms();

    this.$socket.client.on("deletedMessage", ({ messageId, parentId }) => {
      if (this.selectedRoom.roomId == parentId) {
        const messages = [...this.messages];
        const message = messages.find(
          (message) => message.messageId == messageId
        );
        if (message) {
          console.log("message", message);
          message.deleted = true;
          this.messages = messages;
        }
      }
    });

    this.$socket.client.on("parentSeen", ({ parentId }) => {
      const messages = [...this.messages];
      if (this.selectedRoom.roomId == parentId)
        messages.map((message) => {
          message.seen = true;
          return message;
        });
      this.messages = messages;

      this.rooms.map((room) => {
        if (room.roomId == parentId) room.lastMessage.seen = true;
      });
    });

    // listen to new messages from parent or admin (saved in db)
    this.$socket.client.on(
      "adminMessage",
      async ({
        sentBy, // admin or parent
        parentId,
        message,
        messageId,
        date,
        files,
        temp_id, // temp_id(timestamp) for message sent by admin (not saved in db yet)
      }) => {
        const room = this.rooms.find((room) => room.roomId == parentId);
        if (!room) await this.fetchAndUpdateRooms(); // create new room for parent

        const messages = [...this.messages];
        const message_files = [];

        if (files && files.length > 0) {
          files.map((file) => {
            const splited = file.split(".");
            const splitedName = splited[splited.length - 2].split("--")[1];
            message_files.push({
              name: splitedName,
              type: splited[splited.length - 1],
              url: this.settings.base_url + "/" + file.replaceAll("\\", "/"),
              preview:
                this.settings.base_url + "/" + file.replaceAll("\\", "/"),
            });
          });
        }

        if (sentBy == "admin") {
          const msg = messages.find((message) => message.messageId == temp_id);
          if (!msg) {
            // message sent by defferent admin
            messages.push({
              _id: date,
              messageId: messageId,
              content: message,
              saved: true,
              seen: false,
              senderId: this.currentUserId,
              timestamp: new Date(date).toString().substring(16, 21),
              date: moment(date).format("DD/MM/YYYY"),
              files: message_files,
            });
          } else {
            // message saved in db
            msg.messageId = messageId;
            msg.saved = true;
            msg.seen = false;
            msg.files = message_files;
            msg.content = message;
          }
        } else {
          if (this.selectedRoom.roomId != parentId) {
            this.roomNewMessages(parentId, 1);
          } else {
            messages.push({
              _id: date,
              messageId,
              content: message,
              saved: true,
              seen: false,
              senderId: parentId,
              timestamp: new Date(date).toString().substring(16, 21),
              date: moment(date).format("DD/MM/YYYY"),
              files: message_files,
            });
            this.$socket.client.emit("adminSeen", {
              parentId,
            });
          }
        }

        this.rooms.map((m) => {
          if (m.roomId == parentId) {
            m.lastMessage = {
              _id: messageId,
              content: message,
              senderId: sentBy == "parent" ? parentId : "0",
              timestamp: new Date(date).toString().substring(16, 21),
              new: true,
              saved: true,
              seen: false,
            };
          }
        });

        this.messages = messages;
      }
    );

    // listening for online/offline status
    this.$socket.client.on("chat-status", ({ type, parentId }) => {
      if (type == "parentOffline")
        this.rooms.map((room) => {
          if (room.roomId == parentId) room.users[1].status.state = "false";
          return room;
        });
      else if (type == "parentOnline")
        this.rooms.map((room) => {
          if (room.roomId == parentId) room.users[1].status.state = "online";
          return room;
        });
      else console.log("chat-status", type);
    });

    // get all parent list (to use in add parent modal)
    apiClient
      .post("/parents/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $project: {
              father: {
                firstName: 1,
                lastName: 1,
              },
              mother: {
                firstName: 1,
                lastName: 1,
              },
              _id: 1,
              photo: 1,
            },
          },
        ],
      })
      .then(({ data }) => {
        this.parentList = data;
      });
  },

  methods: {
    handleCancel(e) {
      this.visibleAddParnet = false;
    },
    deleteMessageHandler({ roomId, message }) {
      this.$socket.client.emit("deleteMessage", {
        parentId: roomId,
        messageId: message.messageId,
      });
    },
    async fetchAndUpdateRooms() {
      let unseenMessages = [];
      await apiClient.get("/chat/admin/unseenMessages").then((res) => {
        unseenMessages = res.data;
      });

      const rooms = [];
      let update = false;

      // get all parent chats
      await apiClient
        .get("/chat/admin/parentChats")
        .then((res) => {
          res.data.map((parent) => {
            const name = `${parent.father.firstName} ${parent.father.lastName} / ${parent.mother.firstName} ${parent.mother.lastName}`;
            const photo = parent.photo
              ? this.settings.base_url +
                "/" +
                parent.photo.replaceAll("\\", "/")
              : "-";
            const status = parent.online
              ? { state: "online" }
              : { state: "false" };

            const lastMessage = {
              _id: parent.lastMessage._id,
              content: parent.lastMessage.message,
              senderId:
                parent.lastMessage.sentBy == "parent"
                  ? parent.lastMessage.parentId
                  : "0",
              username: name,
              saved: true,
              timestamp: new Date(parent.lastMessage.createdAt)
                .toString()
                .substring(16, 21),
              seen: parent.lastMessage.seen,
            };
            if (!this.rooms.find((room) => room.roomId == parent._id)) {
              update = true;
              rooms.push({
                roomId: parent._id,
                roomName: name,
                unreadCount:
                  unseenMessages.find((p) => p._id == parent._id)?.count || 0,
                avatar: photo,
                lastMessage,
                users: [
                  {
                    _id: "0",
                    username: "Adminstration",
                    status: {
                      state: "online",
                      lastChanged: "today, 14:30",
                    },
                  },
                  {
                    _id: parent._id,
                    username: name,
                    status: status,
                  },
                ],
              });
            }
          });

          this.rooms.push(...rooms);
          if (this.rooms.length > 0 && !update) {
            this.selectedRoom = this.rooms[0];
            this.$socket.client.emit("adminSeen", {
              parentId: this.selectedRoom.roomId,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.roomsLoaded = true;
        });
    },
    roomNewMessages(roomId, count) {
      const rooms = [...this.rooms];
      rooms.map((room) => {
        if (room.roomId == roomId) {
          room.unreadCount += count;
        }
        return room;
      });
      this.rooms = rooms;
    },
    fetchMessages({ room, options = {} }) {
      this.selectedRoom = room;
      const messages = [];

      this.messagesLoaded = false;

      room.unreadCount = 0;
      this.rooms.map((m) => {
        if (m.roomId == room.roomId) {
          m.unreadCount = 0;
        }
        return m;
      });

      // get messages from db of selected room(parent id)
      apiClient
        .get("/chat/admin/messages/" + room.roomId)
        .then((res) => {
          res.data.map((msg) => {
            const message_files = [];
            if (msg.files && msg.files.length > 0) {
              msg.files.map((file) => {
                const splited = file.split(".");
                const splitedName = splited[splited.length - 2].split("--")[1];
                message_files.push({
                  name: splitedName,
                  type: splited[splited.length - 1],
                  url:
                    this.settings.base_url + "/" + file.replaceAll("\\", "/"),
                  preview:
                    this.settings.base_url + "/" + file.replaceAll("\\", "/"),
                });
              });
            }

            messages.push({
              _id: msg.createdAt,
              messageId: msg._id,
              content: msg.message,
              senderId: msg.sentBy == "admin" ? "0" : msg.parent,
              timestamp: new Date(msg.createdAt).toString().substring(16, 21),
              date: moment(msg.createdAt).format("DD/MM/YYYY"),
              files: message_files,
              seen: msg.seen,
              saved: true,
              deleted: Boolean(msg.deleted),
            });
          });

          setTimeout(() => {
            this.messagesLoaded = true;
            this.messages = messages;

            this.$socket.client.emit("adminSeen", {
              parentId: room.roomId,
            });
          }, 100);
        })
        .catch((err) => {
          console.error(err);
        });
    },

    sendMessage(message) {
      const temp_id = Math.floor(new Date().getTime());
      setTimeout(() => {
        const messages = [...this.messages];
        messages.push({
          _id: temp_id, // temp id
          messageId: temp_id, // temp id
          content: message.content,
          senderId: this.currentUserId,
          timestamp: new Date().toString().substring(16, 21),
          date: moment().format("DD/MM/YYYY"),
          files:
            message.files && message.files.length > 0
              ? this.formattedFiles(message.files)
              : [],
        });
        this.messages = messages;

        // if message has files upload them first
        if (message.files && message.files.length > 0) {
          const data = new FormData();

          for (const file of message.files) {
            data.append("files", file.blob, file.name + "." + file.extension);
          }

          apiClient({
            method: "put",
            url: "/chat/upload",
            data: data,
            headers: {
              "Content-Type": `multipart/form-data;`,
            },

            onUploadProgress: (progressEvent) => {
              let percentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              this.updateFileProgress(temp_id, percentage);
            },
          })
            .then((res) => {
              this.$socket.client.emit("adminMessage", {
                message: message.content,
                parentId: message.roomId,
                files: res.data,
                temp_id,
              });
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          this.$socket.client.emit("adminMessage", {
            message: message.content,
            parentId: message.roomId,
            files: [],
            temp_id,
          });
        }
      }, 50);
    },

    openFile({ file }) {
      if (file.action == "download") window.open(file.file.url, "_blank");
    },
    updateFileProgress(messageId, progress) {
      const message = this.messages.find(
        (message) => message._id === messageId
      );

      if (!message || !message.files) return;
      message.files = message.files.map((item) => ({
        ...item, // Spread the existing properties
        progress: progress, // Update the age property
      }));

      this.messages = [...this.messages];
    },
    formattedFiles(files) {
      const formattedFiles = [];

      files.forEach((file) => {
        const messageFile = {
          name: file.name,
          size: file.size,
          type: file.type,
          extension: file.extension || file.type,
          url: file.url || file.localUrl,
        };

        if (file.audio) {
          messageFile.audio = true;
          messageFile.duration = file.duration;
        }

        formattedFiles.push(messageFile);
      });

      return formattedFiles;
    },
    playNotification() {
      const audio = new Audio("/resources/notification.mp3");
      audio.play();
    },

    verifyAccess(accessRoles) {
      const existing = this.user.access;
      if (existing.all) return true;
      if (
        existing.chat &&
        existing.chat.length > 0 &&
        existing.chat.find((e) => accessRoles.includes(e))
      )
        return true;
      else return false;
    },
    addNewParent() {
      this.visibleAddParnet = true;
    },
    cancelAddParent() {
      this.visibleAddParnet = false;
      this.selectedParent = undefined;
    },
    handleAddParent() {
      if (this.selectedParent == undefined) return;

      const record = this.parentList.find((p) => p._id == this.selectedParent);

      const name = `${record.father.firstName} ${record.father.lastName} / ${record.mother.firstName} ${record.mother.lastName}`;
      const photo = record.photo
        ? this.settings.base_url + "/" + record.photo.replaceAll("\\", "/")
        : "-";
      const room = {
        roomId: this.selectedParent,
        roomName: name,
        unreadCount: 0,
        avatar: photo,
        users: [
          {
            _id: "0",
            username: "Adminstration",
            status: {
              state: "online",
              lastChanged: "today, 14:30",
            },
          },
          {
            _id: record._id,
            username: name,
            status: {
              state: "offline",
            },
          },
        ],
      };

      this.rooms = [room, ...this.rooms];

      this.$socket.client.emit("request-parent-status", {
        parentId: record._id,
      });

      this.cancelAddParent();
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    checkIfParentAlreadyAdded(parentId) {
      return this.rooms.find((r) => r.roomId == parentId);
    },
  },
};
</script>

<style lang="scss">
body {
  font-family: "Quicksand", sans-serif;
}
</style>
